import { StyleSheet } from "@react-pdf/renderer"

// ! Raise to Kim

// Font.register({
//   family: "Lato",
//   fonts: [
//     {
//       src: "https://fonts.gstatic.com/s/lato/v11/h7rISIcQapZBpei-sXwIwg.ttf",
//       fontWeight: 400,
//     },
//     {
//       src: "https://fonts.gstatic.com/s/lato/v11/P_dJOFJylV3A870UIOtr0w.ttf",
//       fontWeight: 400,
//       fontStyle: "italic",
//     },
//     {
//       src: "https://fonts.gstatic.com/s/lato/v11/iX_QxBBZLhNj5JHlTzHQzg.ttf",
//       fontWeight: 700,
//     },
//     {
//       src: "https://fonts.gstatic.com/s/lato/v11/WFcZakHrrCKeUJxHA4T_gw.ttf",
//       fontWeight: 700,
//       fontStyle: "italic",
//     },
//   ],
// })

export const styles = StyleSheet.create({
  watermarkLogo: {
    position: "absolute",
    transform: "rotate(-45deg)",
    marginTop: 375,
    opacity: 0.1,
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
  },
  body: { marginHorizontal: 30, padding: 30 },
  header: {
    textAlign: "center",
    borderBottom: 1,
    borderBottomColor: "#0d6d6e",
    paddingBottom: 15,
    marginTop: 30,
  },
  logo: { width: 120 },
  icon: { width: 60, marginTop: 30 },
  doctorName: { fontWeight: 700, fontSize: 24 },
  doctorAddress: { fontSize: 12, fontStyle: "italic" },
  label: { fontWeight: 700 },
  columns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  patientData: {
    borderTop: 1,
    borderTopColor: "#0d6d6e",
    paddingTop: 15,
    marginTop: 5,
    fontSize: 12,
  },
  patientName: { fontWeight: 700, fontSize: 18 },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 45,
    fontSize: 12,
  },
  doctorSignature: {
    width: 200,
    borderTop: 1,
    borderTopColor: "#0d6d6e",
  },
  doctorSignatureName: {
    textAlign: "center",
    marginBottom: 15,
  },
  doctorSignatureDate: {
    color: "gray",
    paddingBottom: 15,
    textAlign: "center",
  },
  doctorSignatureDateContainer: {
    width: 200,
  },
  prescriptionData: {
    height: 200,
    marginBottom: 30,
    marginHorizontal: 57,
  },
  prescriptionDataMedicineDetails: {
    fontSize: 17,
    fontWeight: 700,
  },
  prescriptionDataIntakeDetails: {
    fontSize: 15,
    marginTop: 9,
    marginBottom: 39,
  },
  prescriptionDataOtherNotes: {
    fontSize: 15,
    marginTop: 5,
  },
  otherRecommendations: {
    fontSize: 17,
    marginHorizontal: 42,
    marginTop: 30,
  },
  disclaimer: {
    textAlign: "center",
    color: "#9e9e9e",
    fontStyle: "italic",
    fontSize: 12,
    marginTop: 30,
  },
})
