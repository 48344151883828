import React, { Fragment } from "react"
import Img from "gatsby-image"
import classNames from "classnames"

import styles from "../utils/viewEprescription.module.scss"
import useEprescriptionImages from "../hooks/useEprescriptionImages"

const PrescriptionDetails = ({ eprescription, doctor }) => {
  const issueMonth =
    eprescription?.issueDate?.month?.value || eprescription?.issueDate?.month
  const issueDate =
    eprescription?.issueDate?.date?.value || eprescription?.issueDate?.date
  const doctorHcpDetails =
    doctor?.healthcareProvider?.find(details => details.type === "doctor") || {}
  const expirationMonth =
    eprescription?.expirationDate?.month?.value ||
    eprescription?.expirationDate?.month
  const expirationDate =
    eprescription?.expirationDate?.date?.value ||
    eprescription?.expirationDate?.date
  const images = useEprescriptionImages()

  return (
    <Fragment>
      <div className="mt-2">
        <Img
          fixed={images?.rxLogo?.childImageSharp?.fixed}
          className="is-hidden-mobile"
        />
        <Img
          fixed={images?.rxLogoMobile?.childImageSharp?.fixed}
          className="is-hidden-desktop is-hidden-tablet"
        />
        <div className="columns is-centered mb-3">
          <div className="column is-10 is-12-mobile ml-3 ml-2-mobile">
            {eprescription?.medicines?.map(medicine => (
              <div className="mb-1">
                <p className="is-size-5 is-size-6-mobile">
                  {medicine.productTitle} #{medicine.quantity}
                </p>
                <p className="is-size-6 is-size-7-mobile">
                  {eprescription?.indication === "Other (please specify)"
                    ? eprescription?.otherIndication
                    : eprescription?.indication?.value ||
                      eprescription?.indication}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="ml-3 ml-2-mobile mb-2">
          {eprescription?.patientInstructions
            ?.split("\n")
            .map(patientInstruction => (
              <p className="is-size-6-mobile">{patientInstruction}</p>
            ))}
        </div>
        <div className="is-flex is-justify-content-flex-end mb-3">
          <div
            className={classNames(
              "is-flex is-flex-direction-column",
              styles["eprescription__doctorDetails"]
            )}
          >
            <p className="has-text-grey is-size-6 is-size-7-mobile has-text-centered">
              {issueDate} {issueMonth} {eprescription?.issueDate?.year}
            </p>
            <p
              className={classNames(
                "is-size-6 is-size-7-mobile mb-1 has-text-centered",
                styles["eprescription__doctorDetailsSignature"]
              )}
            >
              {doctor?.firstName} {doctor?.lastName}
            </p>
            <p className="is-size-6 is-size-7-mobile">
              PRC No.: {doctorHcpDetails?.prcLicenseNumber || "N/A"}
            </p>
            <p className="is-size-6 is-size-7-mobile">
              PTR No.: {doctorHcpDetails?.ptrLicenseNumber || "N/A"}
            </p>
          </div>
        </div>
        <p className="is-size-6 is-size-7-mobile has-text-centered has-text-grey">
          This prescription is valid until {expirationDate} {expirationMonth}{" "}
          {eprescription?.expirationDate?.year}.
        </p>
      </div>
    </Fragment>
  )
}

export default PrescriptionDetails
