import React, { useEffect, useState } from "react"
import classNames from "classnames"
// import { PDFViewer } from "@react-pdf/renderer"

// import EprescriptionDocument from "./EprescriptionDocument"
import EprescriptionUploadPreview from "./EprescriptionUploadPreview"

import styles from "./utils/viewEprescription.module.scss"
import EprescriptionMockupPreview from "./EprescriptionMockupPreview"

const EprescriptionPDFPreview = ({
  eprescription,
  doctor,
  patient,
  clinicAddress,
}) => {
  const [showPreview, setShowPreview] = useState(false)
  const hasAddress = !doctor?.addresses || clinicAddress?.id

  useEffect(() => {
    setTimeout(() => {
      setShowPreview(true)
    }, 1000)
  }, [])

  if (eprescription?.uploadedFile)
    return <EprescriptionUploadPreview eprescription={eprescription} />
  else if (
    (eprescription?.doctorUid || eprescription?.id) &&
    doctor?.id &&
    patient?.id &&
    hasAddress &&
    showPreview
  )
    return (
      <EprescriptionMockupPreview
        doctor={doctor}
        eprescription={eprescription}
        patient={patient}
        clinicAddress={clinicAddress}
      />
    )
  // return (
  //   <PDFViewer width="100%" height="600">
  //     <EprescriptionDocument
  //       doctor={doctor}
  //       eprescription={eprescription}
  //       patient={patient}
  //       clinicAddress={clinicAddress}
  //     />
  //   </PDFViewer>
  // )
  return (
    <div className="is-flex is-justify-content-center">
      <div className={classNames(styles["skeleton"])}></div>
    </div>
  )
}

export default EprescriptionPDFPreview
