import React, { Fragment, useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Form, Formik } from "formik"

import Loading from "elements/Loading"
import Message from "elements/Message"
import FormDate from "elements/Form/FormDate"
import Container from "layout/Container"
import DoctorActions from "./ViewEprescription/DoctorActions"
import PatientActions from "./ViewEprescription/PatientActions"
import EprescriptionPDFPreview from "./ViewEprescription/EprescriptionPDFPreview"
import PatientWelcomeMessage from "../Elements/PatientWelcomeMessage"

import { decrypt } from "../Auth/services/crypto"
import { isBrowser } from "../../services/general"
import { AppContext } from "../../context/AppContext"
import { getAddressesById, getUserById } from "../Doctor/services/patients"
import { hasDoctorRole } from "../Auth/services/authUser"
import { getEprescription } from "./services/eprescription"

const ViewEprescription = ({ location, setIsVerified, isVerified }) => {
  const { state, dispatch } = useContext(AppContext)
  const [eprescription, setEprescription] = useState({})
  const [doctor, setDoctor] = useState({})
  const [clinicAddress, setClinicAddress] = useState({})
  const [patient, setPatient] = useState({})
  const loading = !(
    (eprescription?.doctorUid || eprescription?.id) &&
    doctor?.id &&
    patient?.id
  )
  const urlParams = new URLSearchParams(location.search)
  const encryptedEprescriptionId = urlParams.get("e") || ""
  let eprescriptionId = ""
  if (encryptedEprescriptionId)
    eprescriptionId = decrypt({ encryptedData: encryptedEprescriptionId })

  useEffect(() => {
    const fetchEprescription = async () => {
      const eprescriptionData = await getEprescription({ eprescriptionId })
      setEprescription(eprescriptionData)
    }
    if (hasDoctorRole()) setIsVerified(true)
    if (state?.eprescription?.doctorUid) setEprescription(state?.eprescription)
    else if (eprescriptionId) fetchEprescription()
    else navigate("/patients")
  }, [eprescriptionId, state, setIsVerified])

  useEffect(() => {
    const fetchUserData = async () => {
      if (eprescription?.doctorUid) {
        const doctorData = await getUserById({ id: eprescription?.doctorUid })
        setDoctor(doctorData)
      }
      if (eprescription?.patientUid) {
        const patientData = await getUserById({ id: eprescription?.patientUid })
        setPatient(patientData)
        dispatch({ type: "SAVE_PATIENT", payload: patientData })
      }
    }
    // const fetchPatientData = async () => {
    //   if (eprescription?.patientUid) {
    //     const patientData = await getUserById({ id: eprescription?.patientUid })
    //     setPatient(patientData)
    //     dispatch({ type: "SAVE_PATIENT", payload: patientData })
    //   }
    // }
    // const fetchDoctorData = async () => {
    //   if (eprescription?.doctorUid) {
    //     const doctorData = await getUserById({ id: eprescription?.doctorUid })
    //     setDoctor(doctorData)
    //   }
    // }
    if (!patient?.id || !doctor?.id) fetchUserData()
  }, [eprescription, dispatch, patient, doctor])

  useEffect(() => {
    const fetchUserAddress = async () => {
      if (doctor?.addresses) {
        let addressData = await getAddressesById({ id: doctor?.addresses })
        let clinicAddress = addressData.addresses.filter(
          address => address.type === "hospital"
        )
        if (clinicAddress.length > 0)
          setClinicAddress({ id: addressData.id, ...clinicAddress[0] })
        else setClinicAddress({})
      }
    }
    if (!clinicAddress?.id && doctor?.addresses) fetchUserAddress()
  }, [doctor, clinicAddress])

  useEffect(() => {
    if (isBrowser() && isVerified) window.scroll(0, 0)
  }, [isVerified])

  const handleSubmit = values => {
    let { birthday } = values
    let { month, date, year } = birthday
    if (
      month?.value === patient?.birthday?.month?.value &&
      parseInt(date?.value) === parseInt(patient?.birthday?.date?.value) &&
      parseInt(year) === parseInt(patient?.birthday?.year)
    )
      setIsVerified(true)
  }

  if (loading) return <Loading />
  if (!isVerified)
    return (
      <Fragment>
        <PatientWelcomeMessage />
        <hr />
        <Container isCentered fullhd={8} desktop={10}>
          <p className="is-size-4 has-text-weight-bold has-text-primary has-text-centered">
            Please enter your birthday to view your ePrescription
          </p>
          <Formik
            initialValues={{
              birthday: {
                month: { label: "MMM", value: "" },
                date: { label: "DD", value: "" },
                year: "",
              },
            }}
            onSubmit={handleSubmit}
          >
            {({ values, submitCount }) => (
              <Form>
                <FormDate name="birthday" values={values.birthday} isRequired />
                {submitCount > 0 && (
                  <Message color="danger">
                    Unable to access ePrescription. Please re-enter your
                    birthday or check with your doctor if your data matches our
                    records.
                  </Message>
                )}
                <button
                  type="submit"
                  className="button is-primary is-fullwidth is-medium"
                >
                  View ePrescription
                </button>
              </Form>
            )}
          </Formik>
        </Container>
      </Fragment>
    )
  if (isBrowser() && isVerified)
    return (
      <Fragment>
        <EprescriptionPDFPreview
          eprescription={eprescription}
          doctor={doctor}
          patient={patient}
          clinicAddress={clinicAddress}
        />
        <DoctorActions
          eprescription={eprescription}
          doctor={doctor}
          patient={patient}
          clinicAddress={clinicAddress}
        />
        <PatientActions
          eprescription={eprescription}
          doctor={doctor}
          patient={patient}
          clinicAddress={clinicAddress}
        />
      </Fragment>
    )
  return null
}

export default ViewEprescription
