import React from "react"
import moment from "moment"
import { Document, Image, Page, Text, View } from "@react-pdf/renderer"

import { styles } from "./utils/styles"

import rxIcon from "images/icons/rx-icon.png"
import medgrocerLogo from "images/logos/medgrocer-long.png"
// import { getDailyIntakeString } from "../services/eprescription"

// TODO: Update ePrescription format once all PRs are merged

const EprescriptionDocument = ({
  eprescription,
  patient,
  doctor,
  clinicAddress,
}) => {
  const doctorHcpDetails =
    doctor?.healthcareProvider?.find(details => details.type === "doctor") || {}
  const issueMonth =
    eprescription?.issueDate?.month?.value || eprescription?.issueDate?.month
  const issueDate =
    eprescription?.issueDate?.date?.value || eprescription?.issueDate?.date
  const expirationMonth =
    eprescription?.expirationDate?.month?.value ||
    eprescription?.expirationDate?.month
  const expirationDate =
    eprescription?.expirationDate?.date?.value ||
    eprescription?.expirationDate?.date

  return (
    <Document>
      <Page size="A4" style={styles.main}>
        <Image fixed src={medgrocerLogo} style={styles.watermarkLogo} />
        <View style={styles.body}>
          <View fixed style={styles.header}>
            <Image src={medgrocerLogo} style={styles.logo} />
            {doctor?.id ? (
              <View>
                <Text style={styles.doctorName}>
                  {doctor?.firstName} {doctor?.lastName}, MD
                </Text>
              </View>
            ) : null}
            {clinicAddress?.streetAddress ? (
              <View>
                <Text style={styles.doctorAddress}>{doctor?.email}</Text>
                <Text style={styles.doctorAddress}>{clinicAddress?.name}</Text>
                <Text style={styles.doctorAddress}>
                  {clinicAddress?.streetAddress}, {clinicAddress?.city},{" "}
                  {clinicAddress?.province}
                </Text>
              </View>
            ) : null}
          </View>
          <View fixed style={styles.patientData}>
            {patient?.id ? (
              <View>
                <View style={styles.columns}>
                  <Text style={styles.patientName}>
                    {patient?.firstName} {patient?.lastName}
                  </Text>
                  <Text>
                    <Text style={styles.label}>Date:</Text> {issueDate}{" "}
                    {issueMonth} {eprescription?.issueDate?.year}
                  </Text>
                </View>
                <Text>
                  <Text style={styles.label}>Age/ Sex:</Text>{" "}
                  {moment().diff(
                    `${patient?.birthday?.month?.value} ${patient?.birthday?.date?.value} ${patient?.birthday?.year}`,
                    "years"
                  )}
                  / {patient?.sex}
                </Text>
              </View>
            ) : null}
            {eprescription?.id ? <View></View> : null}
          </View>
          <Image fixed src={rxIcon} style={styles.icon} />
          {eprescription?.medicines?.length > 0 ? (
            <View style={styles.prescriptionData}>
              {eprescription?.medicines?.map(medicine => (
                <View wrap={false}>
                  <View>
                    <Text style={styles.prescriptionDataMedicineDetails}>
                      {medicine.productTitle} #{medicine.quantity}
                    </Text>
                  </View>
                  <View style={styles.prescriptionDataIntakeDetails}>
                    <Text>
                      {eprescription?.indication === "Other (please specify)"
                        ? eprescription?.otherIndication
                        : eprescription?.indication?.value ||
                          eprescription?.indication}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          ) : null}
          {eprescription?.patientInstructions ? (
            <View style={styles.otherRecommendations}>
              <Text>{eprescription?.patientInstructions}</Text>
            </View>
          ) : null}
          {doctor?.id ? (
            <View fixed wrap={false} style={styles.footer}>
              <View>
                <View style={styles.doctorSignatureDateContainer}>
                  <Text style={styles.doctorSignatureDate}>
                    {issueDate} {issueMonth} {eprescription?.issueDate?.year}
                  </Text>
                </View>
                <View style={styles.doctorSignature}>
                  <Text style={styles.doctorSignatureName}>
                    {doctor?.firstName} {doctor?.lastName}
                  </Text>
                  <Text>
                    <Text style={styles.label}>
                      PRC No.: {doctorHcpDetails?.prcLicenseNumber || "N/A"}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={styles.label}>
                      PTR No.: {doctorHcpDetails?.ptrLicenseNumber || "N/A"}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          ) : null}
          <View fixed wrap={false} style={styles.disclaimer}>
            <Text>
              This prescription is valid until {expirationDate}{" "}
              {expirationMonth} {eprescription?.expirationDate?.year}.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default EprescriptionDocument
