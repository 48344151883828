import React from "react"
import moment from "moment"

const PatientDetails = ({ patient, eprescription }) => {
  const issueMonth =
    eprescription?.issueDate?.month?.value || eprescription?.issueDate?.month
  const issueDate =
    eprescription?.issueDate?.date?.value || eprescription?.issueDate?.date

  return (
    <section className="is-flex is-justify-content-space-between">
      <section>
        <p className="is-size-6-mobile">
          {patient?.firstName} {patient?.lastName}
        </p>
        <p className="is-size-6 is-size-7-mobile">
          Age/ Sex:{" "}
          {moment().diff(
            `${patient?.birthday?.month?.value} ${patient?.birthday?.date?.value} ${patient?.birthday?.year}`,
            "years"
          )}
          / {patient?.sex}
        </p>
      </section>
      <section>
        <p className="is-size-6 is-size-7-mobile">
          Date: {issueDate} {issueMonth} {eprescription?.issueDate?.year}
        </p>
      </section>
    </section>
  )
}

export default PatientDetails
