import React, { useEffect, useState } from "react"

import Layout from "layout/Layout"
import Container from "layout/Container"
import { ProgramName } from "elements/Brand"
import ViewEprescription from "components/Eprescription/ViewEprescription"

export default ({ location }) => {
  const [title, setTitle] = useState(
    <span>
      Welcome to the <ProgramName />
    </span>
  )
  const [isVerified, setIsVerified] = useState(false)

  useEffect(() => {
    if (isVerified) setTitle("View ePrescription")
    else
      setTitle(
        <span>
          Welcome to the <ProgramName />
        </span>
      )
  }, [isVerified])

  return (
    <Layout title={title} subtitle="" seoTitle="View ePrescription">
      <Container isCentered fullhd={6}>
        <ViewEprescription
          location={location}
          setIsVerified={setIsVerified}
          isVerified={isVerified}
        />
      </Container>
    </Layout>
  )
}
