import React from "react"
import { ProgramName, Brand } from "./Brand"
import DeliveryDisclaimerMessage from "./DeliveryDisclaimerMessage"

const PatientWelcomeMessage = () => {
  return (
    <section className="content">
      <p>
        Your doctor has nominated you for the{" "}
        <ProgramName className="has-text-weight-bold has-text-primary" />
        . Under this program, you can:
        <br />
        <ul>
          <li>
            Get early access to <Brand />
          </li>
          <li>Have your medicines delivered to your doorstep</li>
          <li>
            Receive patient education materials and on-demand access to nurse
            educators to learn more about how to use <Brand /> and manage your
            condition
          </li>
        </ul>
      </p>

      <p>
        To get your samples, please provide your delivery details and upload a
        valid prescription for <Brand /> by April 1, 2021. Since the program has
        limited slots, enrollments will be taken on a first come, first served
        basis. Enrollments are not considered final until they have been
        verified by our team.
      </p>
      <p>
        <DeliveryDisclaimerMessage />
      </p>
      <p>
        If you have any challenges enrolling or have any questions, please
        contact us at 0968 230 6545 to get support.
      </p>
    </section>
  )
}

export default PatientWelcomeMessage
