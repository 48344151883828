import React, { Fragment, useContext } from "react"
import moment from "moment"
import { navigate } from "gatsby"

import EprescriptionDocument from "./EprescriptionDocument"
import EprescriptionPDFDownloadLink from "./EprescriptionPDFDownloadLink"
import DeliveryDisclaimerMessage from "../../Elements/DeliveryDisclaimerMessage"
import Message from "elements/Message"
import { Brand } from "elements/Brand"

import { AppContext } from "../../../context/AppContext"
import { hasDoctorRole } from "../../Auth/services/authUser"
import { convertPDFToFileObject } from "../services/eprescription"

const PatientActions = ({ eprescription, doctor, patient, clinicAddress }) => {
  const { dispatch } = useContext(AppContext)
  const isPatientRole = !hasDoctorRole()

  const handleRedirectToDeliveryDetails = async () => {
    const prescriptionFile = await convertPDFToFileObject({
      document: (
        <EprescriptionDocument
          eprescription={eprescription}
          doctor={doctor}
          patient={patient}
          clinicAddress={clinicAddress}
        />
      ),
      filename: `${patient?.lastName?.toLowerCase()}_${moment(
        `${eprescription?.issueDate?.month} ${eprescription?.issueDate?.date} ${eprescription?.issueDate?.year}`
      ).format("YYYYMMDD")}.pdf`,
    })
    dispatch({ type: "SAVE_DOCUMENT", payload: prescriptionFile })
    dispatch({
      type: "SAVE_EPRESCRIPTION",
      payload: {
        ...eprescription,
        patient: { ...patient },
        doctor: { ...doctor },
      },
    })
    navigate("/patient/enroll")
  }

  if (isPatientRole)
    return (
      <Fragment>
        <Message color="light" className="mt-1">
          To get your samples, please provide your delivery details and upload a
          valid prescription for <Brand /> by April 1, 2021. Since the program
          has limited slots, enrollments will be taken on a first come, first
          served basis. Enrollments are not considered final until they have
          been verified by our team. <DeliveryDisclaimerMessage size={6} />
        </Message>
        <div className="buttons is-centered">
          <button
            className="button is-primary is-fullwidth mt-3 is-medium"
            onClick={handleRedirectToDeliveryDetails}
          >
            Enroll
          </button>
          <div className="has-text-centered is-size-5 mt-1">
            <EprescriptionPDFDownloadLink
              eprescription={eprescription}
              doctor={doctor}
              patient={patient}
              clinicAddress={clinicAddress}
            />
          </div>
        </div>
      </Fragment>
    )
  return null
}

export default PatientActions
