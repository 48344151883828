import React, { Fragment } from "react"
import classNames from "classnames"

import styles from "../utils/viewEprescription.module.scss"

const DoctorDetails = ({ doctor, clinicAddress }) => {
  return (
    <Fragment>
      <p className="is-size-3 is-size-4-mobile has-text-centered mt-0 mt-1-mobile">
        {doctor?.firstName} {doctor?.lastName}, MD
      </p>
      <p className="is-size-6 is-size-7-mobile has-text-centered">
        {doctor?.email}
      </p>
      <p className="is-size-6 is-size-7-mobile has-text-centered">
        {clinicAddress?.name}
      </p>
      <p className="is-size-6 is-size-7-mobile has-text-centered">
        {clinicAddress?.streetAddress}, {clinicAddress?.city},{" "}
        {clinicAddress?.province}
      </p>
      <hr className={classNames(styles["eprescription__divider"])} />
      <hr className={classNames(styles["eprescription__divider"])} />
    </Fragment>
  )
}

export default DoctorDetails
