import React from "react"
import Img from "gatsby-image"
import classNames from "classnames"

import DoctorDetails from "./EprescriptionMockupPreview/DoctorDetails"
import PatientDetails from "./EprescriptionMockupPreview/PatientDetails"
import PrescriptionDetails from "./EprescriptionMockupPreview/PrescriptionDetails"
import EprescriptionPDFDownloadLink from "./EprescriptionPDFDownloadLink"

import { hasDoctorRole } from "../../Auth/services/authUser"
import styles from "./utils/viewEprescription.module.scss"
import useEprescriptionImages from "./hooks/useEprescriptionImages"

const EprescriptionMockupPreview = ({
  eprescription,
  doctor,
  patient,
  clinicAddress,
}) => {
  const images = useEprescriptionImages()

  let isDoctorRole = hasDoctorRole()

  return (
    <div className="is-flex is-justify-content-center">
      <section>
        <div className={classNames("p-3 p-2-mobile", styles["eprescription"])}>
          <Img fixed={images?.medgrocerLogo?.childImageSharp?.fixed} />
          <DoctorDetails doctor={doctor} clinicAddress={clinicAddress} />
          <PatientDetails patient={patient} eprescription={eprescription} />
          <PrescriptionDetails eprescription={eprescription} doctor={doctor} />
        </div>
        {isDoctorRole && (
          <div className="mt-1">
            <EprescriptionPDFDownloadLink
              doctor={doctor}
              clinicAddress={clinicAddress}
              patient={patient}
              eprescription={eprescription}
            />
          </div>
        )}
      </section>
    </div>
  )
}

export default EprescriptionMockupPreview
