import React, { Fragment } from "react"
import classNames from "classnames"

import styles from "./utils/viewEprescription.module.scss"

const EprescriptionUploadPreview = ({ eprescription }) => {
  let fileName = eprescription.uploadedFile
    .split(".com/o/")[1]
    .split("?alt=media")[0]

  if (fileName.includes(".pdf"))
    return (
      <Fragment>
        <iframe
          title={fileName}
          src={eprescription?.uploadedFile}
          className={classNames(styles["eprescription__iframe"])}
          frameborder="0"
        ></iframe>
      </Fragment>
    )
  return (
    <div className="is-flex is-justify-content-center">
      <img
        src={eprescription.uploadedFile}
        className="image"
        alt="attachment"
      />
    </div>
  )
}

export default EprescriptionUploadPreview
