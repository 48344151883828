import React, { useEffect, useState } from "react"
import moment from "moment"
import { PDFDownloadLink } from "@react-pdf/renderer"

import EprescriptionDocument from "./EprescriptionDocument"

const EprescriptionPDFDownloadLink = ({
  eprescription,
  doctor,
  patient,
  clinicAddress,
}) => {
  const [showDownloadLink, setShowDownloadLink] = useState(false)
  const [fileName, setFileName] = useState("")

  useEffect(() => {
    let { issueDate } = eprescription

    setTimeout(() => {
      setShowDownloadLink(true)
    }, 1)

    if (issueDate?.month && issueDate?.date && issueDate?.year)
      setFileName(
        `[ERX] ${patient?.firstName[0]}${patient?.lastName} ${moment(
          `${issueDate?.month} ${issueDate?.date} ${issueDate?.year}`
        ).format("YYYYMMDD")}`
      )
  }, [eprescription, patient])

  if (eprescription.uploadedFile)
    return (
      <a
        href={eprescription.uploadedFile}
        target="_blank"
        rel="noreferrer noopener"
        download
      >
        Download ePrescription
      </a>
    )
  if (showDownloadLink)
    return (
      <PDFDownloadLink
        document={
          <EprescriptionDocument
            eprescription={eprescription}
            doctor={doctor}
            patient={patient}
            clinicAddress={clinicAddress}
          />
        }
        fileName={`${fileName}.pdf`}
      >
        {({ loading }) => (loading ? "Loading..." : "Download ePrescription")}
      </PDFDownloadLink>
    )
  return null
}

export default EprescriptionPDFDownloadLink
