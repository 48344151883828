import { useStaticQuery, graphql } from "gatsby"

const useEprescriptionImages = () => {
  const data = useStaticQuery(graphql`
    {
      medgrocerLogo: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fixed(height: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      rxLogo: file(relativePath: { eq: "icons/rx-icon.png" }) {
        childImageSharp {
          fixed(height: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      rxLogoMobile: file(relativePath: { eq: "icons/rx-icon.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useEprescriptionImages
