import React, { Fragment, useContext } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"
import moment from "moment"

import SendToPatientModal from "../SendToPatientModal"
import EprescriptionDocument from "./EprescriptionDocument"

import {
  convertPDFToFileObject,
  sendEprescription,
} from "../services/eprescription"
import { modal, toast } from "../../../context/AppReducer"
import { AppContext } from "../../../context/AppContext"
import { hasDoctorRole } from "../../Auth/services/authUser"

const DoctorActions = ({ eprescription, doctor, patient, clinicAddress }) => {
  const isDoctorRole = hasDoctorRole()
  const { dispatch } = useContext(AppContext)

  const handleSubmitError = ({ message }) => {
    dispatch({
      type: modal.HIDE_MODAL,
    })
    dispatch({
      type: toast.SHOW_TOAST,
      payload: {
        message: message,
        color: "danger",
      },
    })
  }

  const handleSendToPatient = async () => {
    const prescriptionFile = await convertPDFToFileObject({
      document: (
        <EprescriptionDocument
          eprescription={eprescription}
          doctor={doctor}
          patient={patient}
          clinicAddress={clinicAddress}
        />
      ),
      filename: `${patient?.lastName?.toLowerCase()}_${moment(
        `${eprescription?.issueDate?.month} ${eprescription?.issueDate?.date} ${eprescription?.issueDate?.year}`
      ).format("YYYYMMDD")}.pdf`,
    })

    sendEprescription({
      eprescription,
      doctor,
      patient,
      clinicAddress,
      prescriptionFile,
      errorCallback: handleSubmitError,
      callback: () => {
        navigate("/complete")
      },
    })
  }

  const handleClickSend = () => {
    dispatch({
      type: modal.SHOW_MODAL,
      payload: {
        heading: "Send ePrescription",
        isCard: true,
        headerClass: `has-text-primary has-background-light is-size-5`,
        content: (
          <SendToPatientModal handleSendToPatient={handleSendToPatient} />
        ),
      },
    })
  }

  const handleBack = async () => {
    if (!eprescription?.id) navigate("/issue-erx/prescription-details")
    else navigate("/patients/view")
  }

  if (isDoctorRole)
    return (
      <Fragment>
        <div className="buttons is-centered">
          <button
            onClick={handleClickSend}
            className={classNames(
              "button is-primary is-fullwidth mt-3 is-medium"
            )}
          >
            Send to Patient
          </button>
        </div>
        <div className="has-text-centered is-size-5">
          <button
            className={classNames("button is-text has-text-primary is-medium")}
            onClick={handleBack}
          >
            Back
          </button>
        </div>
      </Fragment>
    )
  return null
}

export default DoctorActions
