import React, { Fragment, useContext, useState } from "react"
import classNames from "classnames"

import { AppContext } from "../../context/AppContext"

const SendToPatientModal = ({ handleSendToPatient }) => {
  const [loading, setLoading] = useState(false)
  const { state } = useContext(AppContext)
  const { patient } = state

  const handleClick = () => {
    setLoading(true)
    handleSendToPatient()
  }

  return (
    <Fragment>
      <p>
        This ePrescription will be sent to
        <span className="has-text-primary has-text-weight-bold">
          {` ${patient?.firstName} ${patient?.lastName} (${patient?.mobileNumber}) `}
        </span>
        . Are you sure you want to proceed?
      </p>
      <div className="buttons is-centered mt-2">
        <button
          onClick={handleClick}
          className={classNames("button is-primary", {
            "is-loading": loading,
          })}
          disabled={loading}
        >
          Confirm
        </button>
      </div>
    </Fragment>
  )
}

export default SendToPatientModal
