import firebase from "firebase"
import { isBrowser } from "../../../services/general"
import { hasAuthUser } from "../../Auth/services/authUser"
import { hasDoctorPatientRelationship } from "./relationships"
import FireStoreParser from "firestore-parser"
import { generateJWT } from "../../../services/jwt"
import { firebaseApi } from "../../../services/firebase/firebaseApi"

export const ADD_PATIENT = "add"
export const EDIT_PATIENT = "edit"

export const getPatients = async ({ doctorUid }) => {
  if (isBrowser()) {
    const filteredPatients = await firebase
      .firestore()
      .collection("doctorPatients")
      .where("doctorUid", "==", doctorUid)
      .get()

    let fetchedPatients = []
    filteredPatients.forEach(doctorPatient => {
      fetchedPatients.push({ ...doctorPatient.data(), id: doctorPatient.id })
    })

    return fetchedPatients
  }
}

export const handlePatientUpdate = async ({ values, updateCallback, id }) => {
  if (isBrowser()) {
    // Update users collection
    await firebase
      .firestore()
      .collection("users")
      .doc(id)
      .update(values)

    // Update doctors PAt
    await firebase
      .firestore()
      .collection("doctorPatients")
      .doc(values?.doctorPatientUid)
      .update({ patient: values })

    if (updateCallback) updateCallback()
  }
}

export const getUserById = async ({ id }) => {
  if (isBrowser()) {
    if (hasAuthUser()) {
      const user = await firebase
        .firestore()
        .collection("users")
        .doc(id)
        .get()

      return { ...user.data(), id: user.id }
    } else {
      const jwtObject = await generateJWT()
      const accessToken = jwtObject?.data?.access_token
      const request = await firebaseApi({ accessToken: accessToken }).get(
        `users/${id}`
      )
      const userData = FireStoreParser(request?.data?.fields)
      return { ...userData, id: id }
    }
  }
}

export const getPatientData = async ({ patientEmail }) => {
  if (isBrowser()) {
    const filteredUsers = await firebase
      .firestore()
      .collection("users")
      .where("email", "==", patientEmail)
      .get()

    let fetchedUser = {}
    filteredUsers.forEach(user => {
      fetchedUser = { ...user.data(), id: user.id }
    })

    return fetchedUser
  }
}

export const getRelevantPatientData = ({ patientData, formValues }) => {
  return {
    id: patientData?.id,
    firstName: patientData?.firstName || formValues?.firstName || "",
    lastName: patientData?.lastName || formValues?.lastName || "",
    email: patientData?.email || formValues?.email || "",
    mobileNumber: patientData?.mobileNumber || formValues?.mobileNumber || "",
    birthday: patientData?.birthday || formValues?.birthday || {},
    sex: patientData?.sex || formValues?.sex || "",
  }
}

export const createUserDocument = async ({ values }) => {
  if (isBrowser()) {
    let userDocument = {
      birthday: values?.birthday,
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      mobileNumber: values?.mobileNumber,
      sex: values?.sex,
    }

    const createdUser = await firebase
      .firestore()
      .collection("users")
      .add(userDocument)

    return createdUser
  }
}

export const updateUserDocument = async ({ userId, values }) => {
  if (isBrowser()) {
    let fieldsToUpdate = {
      ...values,
    }

    const updatedUser = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update(fieldsToUpdate)

    return updatedUser
  }
}

export const addPatientToDoctor = async ({ values, doctorUid, callbacks }) => {
  if (isBrowser()) {
    try {
      let fetchedPatient = await getPatientData({
        patientEmail: values.email,
      })

      if (fetchedPatient?.id) {
        let patientUid = fetchedPatient.id
        let fieldsToUpdate = {
          sex: values.sex,
          birthday: {
            month: values?.birthday?.month,
            date: values?.birthday?.date,
            year: values?.birthday?.year,
          },
        }
        await updateUserDocument({
          userId: patientUid,
          values: {
            ...fieldsToUpdate,
          },
        })
        fetchedPatient = {
          ...fetchedPatient,
          ...fieldsToUpdate,
          id: patientUid,
        }
        let patientData = getRelevantPatientData({
          patientData: fetchedPatient,
          formValues: values,
        })
        let fetchedRelationship = await hasDoctorPatientRelationship({
          doctorUid,
          patientUid,
        })
        if (!fetchedRelationship?.id) {
          if (callbacks.success) callbacks.success({ patientData })
        } else if (callbacks.alreadyAdded)
          callbacks.alreadyAdded({ patientData })
      } else {
        await createUserDocument({ values })
        let patientData = await getPatientData({ patientEmail: values.email })
        patientData = getRelevantPatientData({
          patientData,
          formValues: values,
        })
        if (callbacks.success) callbacks.success({ patientData })
      }
    } catch {
      if (callbacks.error) callbacks.error()
    }
  }
}

export const getAddressesById = async ({ id }) => {
  if (isBrowser()) {
    if (hasAuthUser()) {
      const addresses = await firebase
        .firestore()
        .collection("addresses")
        .doc(id)
        .get()

      return { ...addresses.data(), id: addresses.id }
    } else {
      const jwtObject = await generateJWT()
      const accessToken = jwtObject?.data?.access_token
      const request = await firebaseApi({ accessToken: accessToken }).get(
        `addresses/${id}`
      )
      const addressData = FireStoreParser(request?.data?.fields)
      return { ...addressData, id: id }
    }
  }
}
